import { Reactive } from 'vue';
import CryptoJS from 'crypto-js';
import { noLogin } from '@config/routes';
import systemInfo from '@/stores/systemInfo';
import projectData from '@/stores/projectData';
import { navigateTo, getRoute, urlReg, parseUrl } from '@utils/router';

/** AES加密解密 */
export const AES = {
	secretKey: {
		// #ifdef APP
		key: CryptoJS.enc.Utf8.parse('9ji1y5amc@qq.com'),
		iv: CryptoJS.enc.Utf8.parse('9240740519579012'),
		// #endif
		// #ifndef APP
		// @ts-ignore
		key: CryptoJS.enc.Utf8.parse('6sa175asc@qq.com'),
		// @ts-ignore
		iv: CryptoJS.enc.Utf8.parse('6549846516589321'),
		// #endif
	},
	encrypt(data: string): string {
		data = String(data);
		let { key, iv } = this.secretKey;

		return CryptoJS.AES.encrypt(data, key, {
			iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
		}).toString();
	},
	decrypt(data: string): string {
		let { key, iv } = this.secretKey;
		return CryptoJS.AES.decrypt(data, key, {
			iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
		}).toString(CryptoJS.enc.Utf8);
	},
};

/** 获取当前打包平台 */
export const getPlatform = (): string => {
	let plat;
	// #ifdef H5
	plat = 'h5';
	// #endif
	// #ifdef MP
	plat = 'mp';
	// #endif
	// #ifdef APP-PLUS
	plat = uni.getSystemInfoSync().platform;
	// #endif
	return plat;
};

/** 合并对象 */
export const assignObj = (obj1: any, obj2: any) => {
	for (let key in obj2)
		key in obj1 && ![undefined, null].includes(obj2[key]) && (obj1[key] = obj2[key]);
	return obj1;
};

/** 检查更新 */
export const checkUpdated = () => {
	// #ifdef MP
	const updateManager = uni.getUpdateManager();
	updateManager.onUpdateReady((res) => {
		uni.showModal({
			title: '更新提示',
			content: '新版本已经准备好，请重启应用',
			showCancel: false,
			success(res) {
				updateManager.applyUpdate();
			},
		});
	});
	// #endif
	// #ifdef APP-PLUS
	projectData()
		.fetchVersionData()
		.then((info) => {
			let { desc, isForce, isNew, updateUrl, content } = info;
			if (isNew != 1 && updateUrl) {
				uni.showModal({
					content,
					title: desc,
					showCancel: isForce != 1,
					cancelText: '稍后进行',
					confirmText: '立即更新',
					success: ({ confirm }) => {
						if (confirm) {
							if (systemInfo().plat == 'android') {
								uni.showLoading({
									mask: true,
									title: '正在下载更新...',
								});
								toUpdate(updateUrl);
							} else {
								navigateTo(updateUrl);
							}
						}
					},
				});
			}
		});
	// #endif
};

/** 下载更新 */
const toUpdate = (updateUrl: string) => {
	uni.downloadFile({
		url: updateUrl,
		success(res) {
			if (res.statusCode === 200) {
				uni.hideLoading();
				uni.showToast({
					title: '下载完成',
					icon: 'success',
				});
				plus.runtime.install(res.tempFilePath);
			} else {
				uni.hideLoading();
				uni.showToast({
					icon: 'error',
					title: '下载失败',
				});
			}
		},
		fail(res) {
			uni.hideLoading();
			uni.showToast({
				icon: 'error',
				title: '下载失败',
			});
		},
	});
};

/** 登录 */
export const toLogin = (type = 'push') => {
	let { path } = getRoute();
	if (!noLogin.includes(path)) {
		uni.showModal({
			title: '提示',
			content: '请先登录',
			showCancel: true,
			success: ({ confirm, cancel }) => {
				if (confirm) {
					navigateTo('/pages/mycenter/login/index', type);
				}
			},
		});
	}
};

/** 商户登录 */
export const toMerchantLogin = debounce((url?: string) => {
	uni.showModal({
		title: '提示',
		content: '请先登录商户端',
		showCancel: true,
		success: ({ confirm, cancel }) => {
			if (confirm) {
				let path = '/pages/shop/login';
				url && (path += `?url=${url}`);
				navigateTo(path);
			}
		},
	});
}, 300);

/** 解析链接码 */
export const parseCode = (code: string) => {
	if (urlReg.test(code)) {
		const { query } = parseUrl(code);
		return query?.code;
	}
	return code;
};

export const getDeviceId = () => {
	const { deviceId } = uni.getSystemInfoSync();
	return AES.encrypt(deviceId);
};

export const isWxBrowser = () => {
	// #ifdef H5
	var ua = navigator.userAgent.toLowerCase();
	return ua.includes('micromessenger');
	// #endif
	// #ifndef H5
	return false;
	// #endif
};

export const chooseLocation = async (params: Reactive<any>) => {
	try {
		const { latitude, longitude, address } = await uni.chooseLocation();
		const { area, city, detail, province } = await request.post(port.REGION_DISTINGUISHADDRESS, {
			text: address,
		});
		const data = {
			lat: latitude,
			lng: longitude,
			area,
			city,
			detail,
			province,
		};
		assignObj(params, data);
	} catch (error) {
		uni.showToast({
			title: '获取地址失败',
			icon: 'none',
		});
	}
};

export const confirmModal = (desc: string, fn: Function) => {
	uni.showModal({
		title: '提示',
		content: desc,
		showCancel: true,
		success: ({ confirm, cancel }) => {
			confirm && fn();
		},
	});
};
