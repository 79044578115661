<script setup lang="ts">
import qs from 'qs';
import port from '@config/port';
import { ref, watch } from 'vue';
import request from '@utils/request';
import systemInfo from '@/stores/systemInfo';
import { checkUpdated } from '@utils/methods';
import projectData from '@/stores/projectData';
import { SYSTEM_INFO } from '@config/constant';
import { onLaunch, onShow } from '@dcloudio/uni-app';

const inviteCode = ref(''),
	sysInfo = systemInfo(),
	prjData = projectData();

sysInfo.$subscribe((mutation, state) =>
	uni.setStorage({
		key: SYSTEM_INFO,
		data: state,
	})
);

const bindMid = debounce(() => {
	if (sysInfo.token && inviteCode.value) {
		request.get({
			resErr: true,
			showLoad: false,
			params: { inviteCode: inviteCode.value },
			port: port.MEMBER_BINDMEMBER,
		});
	}
}, 300);

onLaunch(async ({ query }: any) => {
	sysInfo.setSysData(query);

	watch(inviteCode, () => bindMid(), { immediate: true });

	watch(
		() => sysInfo.token,
		(token) => {
			bindMid();
			prjData.fetchConfig('init');
			prjData.fetchUserInfo('init');
			token && prjData.getShareData();
		},
		{ immediate: true }
	);
});

onShow(({ query: { inviteCode: _inviteCode, scene } }: any) => {
	checkUpdated();
	inviteCode.value = _inviteCode;
	if (scene) {
		scene = decodeURIComponent(scene);
		const { inviteCode: _inviteCode2 } = qs.parse(scene);
		_inviteCode2 && (inviteCode.value = _inviteCode2 as string);
	}
});
</script>

<style lang="scss">
@import '@climblee/uv-ui/index.scss';

@import '@/assets/styles/public.scss';
@import '@/assets/styles/basic-form.scss';

</style>
