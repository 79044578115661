<template>
	<view class="common-tabbar">
		<view class="placeholder">
			<view class="box"></view>
			<bottom-box></bottom-box>
		</view>
		<view class="content">
			<view class="box">
				<template :key="index" v-for="(item, index) in tabbar">
					<view
						class="item"
						:class="{ active: item.path == path }"
						@tap="item.path != path && $navigateTo(item.path)"
					>
						<image :src="item.path == path ? item.actIcon : item.icon" mode="aspectFit" />
						<view class="name">{{ item.text }}</view>
					</view>
					<view
						class="center-btn btn"
						v-if="prjData.isOnline && index == tabbar.length / 2 - 1"
						@tap="$navigateTo('/pages/shop/goods/edit')"
					>
						<uv-icon name="plus" size="56rpx" color="#fff"></uv-icon>
					</view>
				</template>
			</view>
			<bottom-box></bottom-box>
		</view>
	</view>
</template>

<script lang="ts" setup>
import { onShow } from '@dcloudio/uni-app';
import systemInfo from '@/stores/systemInfo';
import { reactive, onMounted, getCurrentInstance } from 'vue';

const tabbar = reactive([
	{
		text: '首页',
		path: '/pages/home',
		icon: '/static/images/home.png',
		actIcon: '/static/images/home-act.png',
	},
	// {
	// 	text: '发现',
	// 	path: '/pages/community/index',
	// 	icon: '/static/images/discover.png',
	// 	actIcon: '/static/images/discover-act.png',
	// },
	// {
	// 	text: '消息',
	// 	path: '/pages/message/index',
	// 	icon: '/static/images/message.png',
	// 	actIcon: '/static/images/message-act.png',
	// },
	{
		text: '我的',
		path: '/pages/mycenter/index',
		icon: '/static/images/mycenter.png',
		actIcon: '/static/images/mycenter-act.png',
	},
]);

const prjData = projectData();
const instance = getCurrentInstance();

defineProps<{
	path: string;
}>();

onShow(() => uni.hideTabBar({ fail: () => {} }));

onMounted(() => {
	prjData.fetchVersionData();
	uni
		.createSelectorQuery()
		.in(instance)
		.select('.common-tabbar .placeholder')
		.boundingClientRect(({ height }: any) => (systemInfo().th = height))
		.exec();
});
</script>

<style lang="scss" scoped>
.common-tabbar {
	.content {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 99;
		width: 100%;
		background-color: #fff;
		border-top: $border;
	}
	.placeholder {
		height: 132rpx;
	}
	.content {
		.box {
			height: 98rpx;
			display: flex;
			align-items: flex-end;
			.item {
				flex: 1;
				height: 100%;
				@include center;
				flex-direction: column;
				&.active .name {
					color: $main-color;
				}
				image {
					width: 40rpx;
					height: 40rpx;
				}
				.name {
					font-size: 22rpx;
					color: #666666;
					line-height: 32rpx;
				}
			}
			.center-btn {
				width: 110rpx;
				height: 110rpx;
				@include center;
				background-color: $main-color;
				border-radius: 50%;
				margin-bottom: 12rpx;
			}
		}
	}
}
</style>
